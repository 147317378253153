
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, url } from '@vuelidate/validators'
import context, { IModel } from '@cloudfun/core'

export default defineComponent({
  setup() {
    const application = context.root
    const configuration = reactive({
      AdminTitle: '',
      PortalTitle: '',
      PortalMetaKeywords: '',
      PortalMetaDescription: '',
      ServicePhone: '',
      CompanyFax: '',
      CompanyAddress: '',
      ServiceEmail: '',
      OpenTime: '',
      CloseTime: '',
      Facebook: '',
      Instagram: '',
      Twitter: '',
      LINE: '',
      TrackingCode: ''
    })

    const rules = {
      AdminTitle: { required },
      PortalTitle: { required },
      PortalMetaKeywords: { required },
      PortalMetaDescription: { required },
      ServicePhone: {},
      CompanyFax: {},
      CompanyAddress: {},
      ServiceEmail: { email },
      OpenTime: {},
      CloseTime: {},
      Facebook: { url },
      Instagram: { url },
      Twitter: { url },
      LINE: { url },
      TrackingCode: {}
    }

    const uploadedYears = ref("");
    const validate = useVuelidate(rules, toRefs(configuration))

    const save = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        context.send('warning', {
          subject: '資料有誤',
          content: '資料驗證失敗，請依提示進行修正後再試一次'
        })
      } else if (application) {
        application.model.dispatch('configuration/update', configuration).then(
          () => { // success
            application.model.dispatch('configuration/read')
            context.send('info', {
              subject: '更新成功',
              content: '系統配置已更新'
            })
          },
          failure => {
            context.send('error', {
              subject: '更新失敗',
              content: failure.message
            })
          }
        )
      }
    }

    const loadCalendarYears = (model: IModel) => {
      model.dispatch('calendar/uploadedYears').then(value => {
        if (value) uploadedYears.value = `已上傳年分: ${value.map((e: number) => e - 1911).join(', ')}`;
      });
    }

    onMounted(() => {
      if (application) {
        application.model.dispatch('configuration/read').then(value => Object.assign(configuration, value));
        loadCalendarYears(application.model);
      }
    })

    return {
      holidayFileInput: ref(),
      isImporting: ref(false),
      configuration,
      uploadedYears,
      validate,
      loadCalendarYears,
      save
    }
  },
  methods: {
    onImport(evt: Event) {
      this.isImporting = true;
      const files = (evt.target as HTMLInputElement).files;
      if (!files) return;
      const formData = new FormData();
      for (const f of files) {
        formData.append("files", f);
      }
      this.$model.dispatch("calendar/import", formData)
        .then(
          _ => {
            this.$send("info", "匯入成功");
            this.loadCalendarYears(this.$model);
          },
          failure => this.$send("error", failure)
        )
        .finally(() => {
          this.isImporting = false;
        })
    }
  }
})
